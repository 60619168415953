import { createContext, useCallback, useEffect, useState } from "react";
import { baseUrl, getRequest } from "../utils/services";
import axios from 'axios';
import {io} from "socket.io-client";


export const ChatContext = createContext();

export const ChatContextProvider = ({ children,  admin }) => {
    const [userChats, setUserChats] = useState([]);
    const [isUserChatsLoading, setIsUserChatsLoading] = useState(false);
    const [userChatsError, setUserChatsError] = useState(null);
    const [potentialChats, setPotentialChats] = useState([]);
    const [currentChat, setCurrentChat] = useState(null);
    const [messages, setMessages] = useState(null);
    const [isMessageLoading, setIsMessageLoading] = useState(false);
    const [messagesError, setMessagesError] = useState(null);
    const [sendTextMessageError, setSendTextMessageError] = useState(null);
    const [newMessage, setNewMessage] = useState(null);
    const [socket, setSocket] = useState(null)
    const [onlineUsers, setOnlineUsers] = useState([])
    const [notifications, setNotifications] = useState([])
    const [allUsers, setAllUsers] = useState([])

useEffect(()=>{
  const newSocket=io("https://tauschsocket.tauschet.com");
  setSocket(newSocket)

return()=>{
  newSocket.disconnect()
}
},[admin]);


//add online users
useEffect(() => {
  if(socket === null) return;
  socket.emit("addNewUser",admin?.id)
  socket.on("getOnlineUsers", (res)=>{
    setOnlineUsers(res)
  });
  return()=>{
    socket.off("getOnlineUsers")
  }
}, [socket]);


// send message
useEffect(() => {
  if(socket === null) return;
  
  const recipientUserId = currentChat && currentChat.length > 0 
  ? (() => {
      try {
        const members = JSON.parse(currentChat[0]?.members);
        return members.find(memberId => memberId !== String(admin.id));
      } catch (error) {
        console.error("Failed to parse members:", error);
        return null;
      }
    })()
  : null;


  socket.emit("sendMessage",{...newMessage,recipientUserId})


}, [newMessage]);



// recieve message and notifications
useEffect(() => {
  if (socket === null) return;

  // Handle incoming messages
  socket.on("getMessage", (res) => {
    if (currentChat?.id !== res.chatId) return;
    setMessages((prevMessages) => [...prevMessages, res]);
  });

  // Handle notifications
  socket.on("getNotification", (res) => {
    if (socket && currentChat && currentChat[0]) {
  
      // Parse the members string into an array
      const parsedMembers = currentChat[0]?.members ? JSON.parse(currentChat[0].members) : [];
  
      const isChatOpen = Array.isArray(parsedMembers) && parsedMembers.some((id) => id === String(res.senderId));
  
  
      if (isChatOpen) {
        setNotifications((prev) => [{ ...res, isRead: true }, ...prev]);
        return;
      }
    }
  
    // If no currentChat or isChatOpen is false, set notification as unread
    setNotifications((prev) => [res, ...prev]);
  });
  

  return () => {
    socket.off("getMessage");
    socket.off("getNotification");
  };
}, [socket, currentChat]);




    useEffect(() => {
        const getUsers = async () => {
          try {
            const response = await axios.get(`${baseUrl}/auth/user`);
    
            if (response.data.error) {
              return console.log("Error fetching users...", response.data);
            }
            const pChats = response.data.filter((u) => {
              let isChatCreated = false;
              // if (admin.id === u.id) return false;
              if (userChats) {
                isChatCreated = userChats.some((chat) => {
                  return chat.members[0] === u.id || chat.members[1] === u.id;
                });
              }
              return !isChatCreated;
            });
    
            setPotentialChats(pChats);
            setAllUsers(response.data)
          } catch (error) {
            console.error("Error fetching users...", error);
          }
        };
    
        getUsers();
      }, [userChats]);
    

    useEffect(() => {
        const getUserChats = async () => {
            if (admin?.id) {
                setIsUserChatsLoading(true);
                setUserChatsError(null);
                try {
                    const response = await getRequest(`${baseUrl}/chat/${admin?.id}`);

                    if (response.error) {
                        setUserChatsError(response);
                    } else {
                      console.log(response)
                    
                        setUserChats(Array.isArray(response) ? response : [response]);
                    }
                } catch (error) {
                    setUserChatsError(error);
                } finally {
                    setIsUserChatsLoading(false);
                }
            }
        };

        getUserChats();
    }, [admin,notifications]);

    useEffect(() => {
      const getMessages = async () => {
        setIsMessageLoading(true);
        setMessagesError(null);
        
        try {
            const response = await axios.get(`${baseUrl}/messages/${currentChat[0]?.id}`);
            setIsMessageLoading(false);
            
            // Check if the response data contains errors
            if (response.data.error) {
                setMessagesError(response.data.error);
            } else {
                setMessages(response.data); // Set the messages from the response
            }
        } catch (error) {
            setIsMessageLoading(false);
            console.error('Error fetching messages:', error);
            setMessagesError(error); // Set the error if something went wrong
        }
    };

      getMessages();
  }, [currentChat]);


// Adjusted sendTextMessage to use FormData
const sendTextMessage = useCallback(
  async (itemId, textMessage, sender, currentChatId, image, setTextMessage) => {
    if (!textMessage && !image) return null;

    try {
      const formData = new FormData();
      formData.append('chatId', currentChatId);
      formData.append('senderId', sender);
      formData.append('text', textMessage || "");
      formData.append('itemId', itemId);
      if (image) {
        formData.append('image', image); // Directly append the image file
      }

      // Send the request with FormData
      const response = await axios.post(
        `${baseUrl}/messages`,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' }, // Set content type for FormData
        }
      );

      if (response.data.error) {
        return setSendTextMessageError(response.data);
      }

      setNewMessage(response.data);
      setMessages((prev) => [...prev, response.data]);
      setTextMessage(''); // Clear the text message input
    } catch (error) {
      
      console.error("Error sending message:", error);
      setSendTextMessageError(error.response ? error.response.data : error.message);
    }
  },
  []
);

  


  const updateCurrentChat = useCallback((chat) => {
    setCurrentChat(chat);
  }, []);

    const createChat = useCallback(async (firstId, secondId) => {
      try {
        const response = await axios.post(
          `${baseUrl}/chat`,
          { firstId, secondId },
          { headers: { 'Content-Type': 'application/json' } }
        );
        
        setUserChats((prev) => [...prev, response.data]);
      } catch (error) {
        console.log("Error creating chat ... ", error.response ? error.response.data : error.message);
      }
    }, [ setUserChats]);

    const markAllAsRead=useCallback((notifications)=>{
      const mNotifications=notifications.map((n)=>{
        return {...n,isRead:true}
      });
      setNotifications(mNotifications);
    },[]);

    const markOneAsRead = useCallback((n, userChats, user, notifications) => {

    
      const desiredChat = userChats.find((chat) => {
        // Parse the members string into an array
        const chatMembers = JSON.parse(chat.members || "[]");
    
        // Create an array with the user ID and the sender ID
        const targetMembers = [String(user.id), n.senderId];
    
        // Check if all members of the chat are in the targetMembers array
        const isDesiredChat = chatMembers.every((member) => {
          return targetMembers.includes(member);
        });
        return isDesiredChat;
      });
    
      const mNotifications = notifications.map((el) => {
        if (n.senderId === el.senderId) {
          return { ...n, isRead: true };
        } else {
          return el;
        }
      });
      
      updateCurrentChat([desiredChat]);
      setNotifications(mNotifications);
    }, [userChats, admin, notifications,currentChat,updateCurrentChat]);
    
    const markThisUserNotificationAsRead=useCallback((personalNotification,notifications)=>{
      const mNotifications=notifications.map((el)=>{
        let notification;
        personalNotification.forEach((n)=>{
          if(n.senderId===el.senderId){
            notification={...n,isRead:true};
          }
          else{
            notification=el;
          }
        });
        return notification;
      });
      setNotifications(mNotifications);
    },[])

    return (
        <ChatContext.Provider value={{
            userChats,
            isUserChatsLoading,
            userChatsError,
            potentialChats,
            createChat,
            updateCurrentChat,
            currentChat,
            messages,
            isMessageLoading,
            messagesError,
            sendTextMessage,
            onlineUsers,
            notifications,
            allUsers,
            markAllAsRead,
            markOneAsRead,
            markThisUserNotificationAsRead,
            
        }}>
            {children}
        </ChatContext.Provider>
    );
};
